import React from "react";
import Popup from "reactjs-popup";
import styles from "./popup.module.scss";
import CloseIcon from "../../img/icons/194142/icon-close.svg";
import PopupIcon from "../../img/popup_icon.svg";

const contentStyle = {
  maxWidth: "600px",
  width: "90%",
  background: "#FFFFFF",
  borderRadius: "16px",
};

const overlayStyle = {
  background: "rgba(83,113,113, 0.8)",
};

export default function PopupUE({
  title,
  body,
  icon,
  icon_bg,
  opened,
  close,
}: {
  title: string;
  icon: string;
  icon_bg?: boolean;
  body?: string | JSX.Element | JSX.Element[];
  opened?: any;
  close?: any;
}) {
  return (
    <>
      <Popup
        modal
        open={opened}
        closeOnDocumentClick
        closeOnEscape
        contentStyle={contentStyle}
        overlayStyle={overlayStyle}
      >
        <div className={styles.modal}>
          <button className={styles.close} onClick={close}>
            <img src={CloseIcon} alt="Close popup" width={24} height={24} />
          </button>
          <div className={`${styles.icon} ${icon_bg ? styles.square : ""}`}>
            {icon ? (
              <img src={icon} width={90} height={90} alt="modal-image" />
            ) : (
              <img src={PopupIcon} width={90} height={90} alt="modal-image" />
            )}
          </div>
          <div className={styles.title}>{title}</div>
          <div className={styles.content}>{body}</div>
        </div>
      </Popup>
    </>
  );
}
