import React, { useState } from "react";
import Number from "../../components/number/number";
import styles from "../../styles/pages/loyalty.module.scss";
import generalStyles from "../../styles/generals.module.scss";
import CallToActionCard from "../../components/call-to-action-card/call-to-action-card";
import GiftsCard from "../../components/gift-cards/gifts-card";
import { LoyaltyLog } from "../../services/loyalty.service";
import UserService, { User } from "../../services/user.service";
import { LoyaltyLogs } from "../../components/loyalty-logs/loyalty-logs";
import { useTranslation } from "react-i18next";
import SeedImage from "../../img/icons/194142/icon-seed.svg";
import EmployeeImage from "../../img/icons/194142/icon-employee.svg";
import BuildingImage from "../../img/icons/194142/icon-building.svg";
import ReinvestIcon from "../../img/reinvest_icon.svg";
import UppiesIcon from "../../img/uppies.svg";
import UppiesImage from "../../img/uppies_image.svg";

// Loyalty icons
import AsAdventureImage from "../../img/giftcards/asadventure.png";
import ResengoImage from "../../img/giftcards/resengo.png";
import NonProfitImage from "../../img/giftcards/non-profit.png";
import PopupUE from "../../components/popup/Popup";
import ArtCraftImage from "../../img/giftcards/art-craft.png";
import SolidarityImage from "../../img/giftcards/upgrade-solidarity.png";
import BikeImage from "../../img/giftcards/bike.png";
import BenefitsBlocks from "../../components/benefits/benefits";
import Loading from "../../components/loading/Loading";

export default function Loyalty({
  user,
  loyaltyLogs,
  loading,
  nrUppies,
}: {
  user: User | undefined;
  loyaltyLogs: LoyaltyLog[];
  loading: boolean;
  nrUppies: number;
}) {
  const { t } = useTranslation("loyalty");

  const [popup, setPopup] = useState<boolean>();
  const [popup_confirm, setPopupConfirm] = useState<boolean>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const openConfirmPopup = () => {
    setPopupConfirm(true);
  };
  const closeConfirmPopup = () => {
    setPopupConfirm(false);
  };

  const openPopup = () => {
    setPopup(true);
  };
  const closePopup = () => {
    setPopup(false);
  };

  const onClick = () => {
    setSubmitting(true);
    UserService.ReInvest();
    return new Promise<void>(() => {
      setTimeout(() => {
        closeConfirmPopup();
        openPopup();
        setSubmitting(false);
      }, 1500);
    });
  };

  return (
    <>
      {user && loyaltyLogs && !loading ? (
        <div className={styles.loyalty}>
          <div className={`${generalStyles.title}`}>Loyalty</div>
          <Number
            singleBlock={true}
            numberBlockLink="/loyalty"
            icon={UppiesIcon}
            number={nrUppies}
            unit={t("total-uppies")}
            bgColor="purple"
          />
          <h2>{t("earn-uppies")}</h2>
          <div className={`${generalStyles.global_text}`}>
            {t("earn-uppies-text")}
          </div>
          <div className={styles.displayFlex}>
            <CallToActionCard
              href={"/new-lead-investor"}
              text={t("bring_investor")}
              icon={SeedImage}
            />
            <CallToActionCard
              href={"/new-lead-employee"}
              text={t("bring_employee")}
              icon={EmployeeImage}
            />
            <CallToActionCard
              href={"/new-lead-property"}
              text={t("bring_building")}
              icon={BuildingImage}
            />
          </div>
          <div className={generalStyles.title}>{t("cash-uppies")}</div>
          <div
            className={`${generalStyles.global_text} ${styles.cash_uppies_text}`}
          >
            {t("cash-uppies-text")}
          </div>
          <BenefitsBlocks
            title={t("uppies_explainer_title")}
            text={t("uppies_explainer_body")}
            img={UppiesImage}
            position="img_position_right"
          />

          <h3>{t("non-profits")}</h3>
          <div className={`${styles.displayFlex} ${styles.giftCardsWrapper}`}>
            <GiftsCard
              label={t("organisation_choice")}
              text={t("organisation_choice_text")}
              href={"/cash-in-uppies?type=Non Profit"}
              image={NonProfitImage}
            />
            <GiftsCard
              label={"Upgrade solidarity"}
              text={t("solidarity_text")}
              href={"/cash-in-uppies?type=Solidarity"}
              image={SolidarityImage}
              tooltip={t("tooltip_solidarity")}
            />
          </div>

          <h3>{t("gift-cards")}</h3>
          <div className={`${styles.displayFlex} ${styles.giftCardsWrapper}`}>
            <GiftsCard
              text={t("sport_text")}
              label={"Sport"}
              href={"/cash-in-uppies?type=sport"}
              image={AsAdventureImage}
            />
            <GiftsCard
              text={t("lifestyle_text")}
              label={"Lifestyle"}
              href={"/cash-in-uppies?type=lifestyle"}
              image={ResengoImage}
            />
            <GiftsCard
              text={t("tech_text")}
              label={"Tech"}
              href={"/cash-in-uppies?type=technology"}
              image={ArtCraftImage}
            />
            <GiftsCard
              text={t("bike_republic_text")}
              label={"Bike Republic"}
              href={"/cash-in-uppies?type=republic"}
              image={BikeImage}
            />
          </div>

          {user.investor_role ? (
            <div className={`${styles.benefit_block}`}>
              <img
                src={ReinvestIcon}
                alt="project image"
                width={48}
                height={48}
              />
              <div className={styles.text}>
                <div className={styles.title}>{t("reinvest_title")}</div>
                {t("reinvest_body")}
                <div>
                  <a
                    className={styles.link}
                    onClick={() => {
                      openConfirmPopup();
                    }}
                  >
                    {t("reinvest_cta")}
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {user.investor_role && user.owner && user.owner.first_name ? (
            <PopupUE
              title={
                t("reinvest_modal_title") +
                user.owner.first_name +
                t("reinvest_modal_title_2")
              }
              body={
                <button
                  disabled={isSubmitting}
                  onClick={onClick}
                  className={`${generalStyles.button_modal} ${generalStyles.green}`}
                >
                  {isSubmitting ? (
                    <span className={generalStyles.spinner} />
                  ) : (
                    t("submit")
                  )}
                </button>
              }
              icon={
                user.owner
                  ? process.env.REACT_APP_IMG_URL + user.owner.image
                  : ""
              }
              icon_bg={true}
              opened={popup_confirm}
              close={closeConfirmPopup}
            />
          ) : (
            ""
          )}

          {user.investor_role && user.owner && user.owner.first_name ? (
            <div>
              <PopupUE
                title={t("reinvest_confirm_modal_title")}
                body={
                  user.owner
                    ? user.owner.first_name
                    : "" + t("reinvest_confirm_modal_body")
                }
                icon=""
                opened={popup}
                close={closePopup}
              />
            </div>
          ) : (
            ""
          )}

          <div className={generalStyles.title}>{t("activity")}</div>
          <LoyaltyLogs logs={loyaltyLogs} noLogsMessage={t("no_activity")} />
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}
