import React, { Suspense, useEffect, useState } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { SafeArea } from "capacitor-plugin-safe-area";
import AuthenticationGuard from "./components/authentication-guard/authentication-guard";
import Index from "./pages";
import Login from "./pages/auth/Login";
import Layout from "./components/layout/layout";
import "./App.css";
import "./styles/globals.scss";
import PasswordResetRequest from "./pages/auth/PasswordResetRequest";
import CheckMailbox from "./pages/auth/CheckMailbox";
import Investments from "./pages/investments/Investments";
import InvestmentDetail from "./pages/investments/InvestmentDetail";
import Sell from "./pages/investments/Sell";
import SubmitSell from "./pages/investments/SubmitSell";
import Loyalty from "./pages/loyalty/Loyalty";
import Benefits from "./pages/benefits";
import Documents from "./pages/documents";
import News from "./pages/news";
import Events from "./pages/events";
import Profile from "./pages/profile/Profile";
import Notifications from "./pages/notifications";
import EditProfile from "./pages/profile/EditProfile";
import Languages from "./pages/profile/Languages";
import Helpcenter from "./pages/profile/Helpcenter";
import ProfileNotifications from "./pages/profile/ProfileNotifications";
import NewLeadForm from "./pages/form/NewLead";
import CashInForm from "./pages/form/CashIn";
import UserService, {
  LanguageSetting,
  User,
  UserDocument,
} from "./services/user.service";
import AskForAccount from "./pages/auth/AskForAccount";
import MailboxAccount from "./pages/auth/MailboxAccount";
import ProjectService, {
  Investment,
  ProjectItem,
  UserProjects,
} from "./services/project.service";
import NewsService, { NewsItem } from "./services/news.service";
import EventsService, { UpgradeEvent } from "./services/events.service";
import LoyaltyService, { LoyaltyLog } from "./services/loyalty.service";
import { useTranslation } from "react-i18next";
import NotificationsService, {
  NotificationItem,
} from "./services/notifications.service";
import { DateTime } from "luxon";
import { Capacitor } from "@capacitor/core";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import ScrollToTop from "./components/scroll-top/scroll-top";

function App() {
  const [statusBarHeight, setStatusBarHeight] = useState(0);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [cards, setCards] = useState<UserProjects | undefined>(undefined);
  const [news, setNews] = useState<NewsItem[]>([]);
  const [events, setEvents] = useState<UpgradeEvent[]>([]);
  const [projects, setProjects] = useState<ProjectItem[]>([]);
  const [investments, setInvestments] = useState<Investment[]>([]);
  const [loyaltyLogs, setLoyaltyLogs] = useState<LoyaltyLog[]>([]);
  const [documents, setDocuments] = useState<UserDocument[]>([]);
  const [nrUppies, setNrUppies] = useState(0);
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onboardingSetting, setOnboardingSetting] = useState<
    "todo" | "done" | "not_set"
  >("not_set");
  const token = localStorage.getItem("token");
  const lang = navigator.language;
  const { i18n } = useTranslation();

  const { t } = useTranslation("common");

  async function getAllData(language: string, withLoading: boolean) {
    if (withLoading) {
      setLoading(true);
    }

    let newsPromise = undefined;
    let userPromise = undefined;
    let userProjectsPromise = undefined;
    let projectsPromise = undefined;
    let eventsPromise = undefined;
    let loyaltyPromise = undefined;
    let documentsPromise = undefined;
    let onboardingPromise = undefined;
    let notificationsPromise = undefined;

    try {
      onboardingPromise = UserService.getUserOnboardingSetting().then(
        (response) => {
          if (response) {
            setOnboardingSetting("done");
          } else {
            setOnboardingSetting("todo");
          }
        }
      );
    } catch {
      // do nothing
    }

    try {
      newsPromise = NewsService.getNews(language).then((response) => {
        if (!response.data) {
          return;
        }

        setNews(response.data);
      });
    } catch {
      // do nothing
    }

    try {
      userPromise = UserService.getUser().then((response) => {
        if (!response.data) {
          return;
        }

        setUser(response.data);
        setNrUppies(response.data.nr_uppies);
      });
    } catch {
      // do nothing
    }

    try {
      userProjectsPromise = ProjectService.getUserProjects().then(
        (response) => {
          if (!response.data) {
            return;
          }

          setCards(response.data);
          setInvestments(response.data.investments);
        }
      );
    } catch {
      // do nothing
    }

    try {
      projectsPromise = ProjectService.getAllProjects(language).then(
        (response) => {
          if (!response.data) {
            return;
          }

          setProjects(response.data);
        }
      );
    } catch {
      // do nothing
    }

    try {
      eventsPromise = EventsService.getEvents(language).then((response) => {
        if (!response.data) {
          return;
        }

        setEvents(response.data);
      });
    } catch {
      // do nothing
    }

    try {
      loyaltyPromise = LoyaltyService.getLogs().then((response) => {
        if (!response) {
          return;
        }

        setLoyaltyLogs(response);
      });
    } catch {
      // do nothing
    }

    try {
      documentsPromise = UserService.getUserDocuments().then((response) => {
        if (!response.data) {
          return;
        }

        setDocuments(response.data);
      });
    } catch {
      // do nothing
    }

    try {
      notificationsPromise = NotificationsService.getNotifications().then(
        (response) => {
          if (!response.data) {
            return;
          }

          const sorted = response.data.sort((a, b) =>
            DateTime.fromISO(b.date).diff(DateTime.fromISO(a.date)).toMillis()
          );
          setNotifications(sorted);
        }
      );
    } catch {
      // do nothing
    }

    await Promise.all([
      newsPromise,
      userPromise,
      userProjectsPromise,
      projectsPromise,
      eventsPromise,
      loyaltyPromise,
      documentsPromise,
      onboardingPromise,
      notificationsPromise,
    ]);

    if (withLoading) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (token !== null) {
      UserService.getUserLanguageSettings()
        .then((response) => {
          i18n.changeLanguage(response).then(() => {});

          getAllData(response, true).then(() => {});

          setInterval(() => {
            // Get all data every 15 mins, so everything stays up to date
            getAllData(response, false).then(() => {});
          }, 1000 * 60 * 15);
        })
        .catch((_) => {
          //if this fails to get a response, log out the user so they can retry with a new token
          localStorage.clear();
          setLoggedIn(false);
          setLoading(false);
          window.location.reload();
          return;
        });
    } else {
      lang === "nl" || lang === "fr"
        ? i18n.changeLanguage(lang)
        : i18n.changeLanguage("nl");
    }

    SafeArea.getStatusBarHeight().then((status) => {
      setStatusBarHeight(status.statusBarHeight);
    });
  }, []);

  useEffect(() => {
    if (!loggedIn) {
      return;
    }

    if (loading) {
      return;
    }

    UserService.getUserLanguageSettings().then((response) => {
      i18n.changeLanguage(response).then(() => {});
    });

    getAllData(i18n.language, true).then(() => {});
    setupPushNotifications().then(() => {});
  }, [loggedIn, i18n]);

  async function setupPushNotifications() {
    if (Capacitor.getPlatform() !== "web") {
      // only register for push notifications in mobile applications
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === "granted") {
          PushNotifications.register().then(() => {});
        } else {
          // do nothing
        }
      });

      PushNotifications.addListener("registration", async (token: Token) => {
        await UserService.updateUserPushNotificationToken(token.value);
      });
    }
  }

  return (
    <>
      <div className="main_wrapper" style={{ paddingTop: statusBarHeight }}>
        <Router>
          <Suspense
            fallback={
              <Layout
                setNotifications={setNotifications}
                onboardingSetting={onboardingSetting}
                notifications={notifications}
                isLoading={loading}
              />
            }
          >
            <ScrollToTop />
            <AuthenticationGuard />
            <Routes>
              <Route
                path={"/"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <Index
                      user={user}
                      investments={investments}
                      events={events}
                      news={news}
                      cards={cards}
                      loading={loading}
                      projects={projects}
                    />
                  </Layout>
                }
              />
              <Route
                path={"/auth/login"}
                element={<Login isLoggedIn={setLoggedIn} />}
              />
              <Route
                path={"/auth/activate-account"}
                element={<AskForAccount />}
              />
              <Route
                path={"/auth/password-reset-request"}
                element={<PasswordResetRequest />}
              />
              <Route path={"/auth/check-mailbox"} element={<CheckMailbox />} />
              <Route
                path={"/auth/check-account"}
                element={<MailboxAccount />}
              />
              <Route
                path={"/investments"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <Investments
                      investments={investments}
                      cards={cards}
                      isLoading={loading}
                      projects={projects}
                    />
                  </Layout>
                }
              />
              <Route
                path={"/investments/:id"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <InvestmentDetail />
                  </Layout>
                }
              />
              <Route
                path={"/investments/:id/sell"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <Sell />
                  </Layout>
                }
              />
              <Route
                path={"/investments/:id/submit-sell"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <SubmitSell />
                  </Layout>
                }
              />
              <Route
                path={"/loyalty"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <Loyalty
                      loyaltyLogs={loyaltyLogs}
                      loading={loading}
                      nrUppies={nrUppies}
                      user={user}
                    />
                  </Layout>
                }
              />
              <Route
                path={"/profile"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <Profile
                      user={user}
                      languageSetting={i18n.language as LanguageSetting}
                    />
                  </Layout>
                }
              />
              <Route
                path={"/profile/edit"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <EditProfile user={user} isLoading={loading} />
                  </Layout>
                }
              />
              <Route
                path={"/profile/notifications"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <ProfileNotifications />
                  </Layout>
                }
              />
              <Route
                path={"/profile/languages"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <Languages />
                  </Layout>
                }
              />
              <Route
                path={"/profile/helpcenter"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <Helpcenter />
                  </Layout>
                }
              />
              <Route
                path={"/benefits"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <Benefits />
                  </Layout>
                }
              />
              <Route
                path={"/documents"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <Documents documents={documents} loading={loading} />
                  </Layout>
                }
              />
              <Route
                path={"/events"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <Events events={events} loading={loading} />
                  </Layout>
                }
              />
              <Route
                path={"/news"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <News news={news} isLoading={loading} />
                  </Layout>
                }
              />
              <Route
                path={"/notifications"}
                element={
                  <Layout
                    onboardingSetting={onboardingSetting}
                    setNotifications={setNotifications}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <Notifications
                      setNotifications={setNotifications}
                      notifications={notifications}
                      isLoading={loading}
                      user={user}
                    />
                  </Layout>
                }
              />
              <Route
                path={"/new-lead-investor"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <NewLeadForm title={t("title_investor")} type="investor" />
                  </Layout>
                }
              />
              <Route
                path={"/new-lead-employee"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <NewLeadForm title={t("title_employee")} type="employee" />
                  </Layout>
                }
              />
              <Route
                path={"/new-lead-property"}
                element={
                  <Layout
                    setNotifications={setNotifications}
                    onboardingSetting={onboardingSetting}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <NewLeadForm title={t("title_property")} type="property" />
                  </Layout>
                }
              />
              <Route
                path={"/cash-in-uppies"}
                element={
                  <Layout
                    onboardingSetting={onboardingSetting}
                    setNotifications={setNotifications}
                    notifications={notifications}
                    isLoading={loading}
                  >
                    <CashInForm
                      nrUppies={nrUppies}
                      setNrUppies={setNrUppies}
                      loading={loading}
                    />
                  </Layout>
                }
              />
            </Routes>
          </Suspense>
        </Router>
      </div>
    </>
  );
}

export default App;
