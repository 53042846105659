import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import LoginImage from "../../img/login.svg";
import styles from "../../styles/pages/auth.module.scss";
import generalStyles from "../../styles/generals.module.scss";
import PasswordInput from "../../components/password-input/password-input";
import AuthService from "../../services/auth.service";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { AvailableResult, NativeBiometric } from "capacitor-native-biometric";
import { Preferences } from "@capacitor/preferences";
import { Dialog } from "@capacitor/dialog";
import { Capacitor } from "@capacitor/core";

export default function Login({
  isLoggedIn,
}: {
  isLoggedIn: (bool: boolean) => void;
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const navigation = useNavigate();
  const { t } = useTranslation("auth");
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [biometricAvailable, setBiometricAvailable] = useState<boolean>(false);

  async function biometricLogin(res: AvailableResult) {
    if (!res.isAvailable) {
      setBiometricAvailable(false);
      return;
    }

    const verified = await NativeBiometric.verifyIdentity({
      negativeButtonText: t("negative-button-text"),
      maxAttempts: 3,
    })
      .then(() => true)
      .catch(() => false);

    if (!verified) {
      setBiometricAvailable(false);
      return;
    }

    const credentials = await NativeBiometric.getCredentials({
      server: process.env.REACT_APP_HOSTNAME || "",
    });

    await submitCredentials(credentials.username, credentials.password);
  }

  async function submitCredentials(email: string, password: string) {
    if (Capacitor.getPlatform() !== "web") {
      Preferences.get({ key: "biometricEnabled" }).then((res) => {
        if (!res.value) {
          Dialog.confirm({
            title: t("confirmation-dialog-title"),
            message: t("confirmation-dialog-message"),
            okButtonTitle: t("confirmation-dialog-confirm"),
            cancelButtonTitle: t("confirmation-dialog-cancel"),
          }).then(async (res) => {
            await Preferences.set({
              key: "biometricEnabled",
              value: res.value.toString(),
            });
            await NativeBiometric.setCredentials({
              username: email,
              password: password,
              server: process.env.REACT_APP_HOSTNAME || "",
            });
          });
        }
      });
    }

    localStorage.clear();
    const result = await AuthService.login(email, password);

    if (!result) {
      setError(true);
      setSubmitting(false);
    } else {
      isLoggedIn(true);
      await navigation("/");
    }
  }

  useEffect(() => {
    Preferences.get({ key: "biometricEnabled" }).then((res) => {
      if (res.value === "true") {
        NativeBiometric.isAvailable().then(async (res) => {
          setBiometricAvailable(true);
          await biometricLogin(res);
        });
      }
    });
  }, []);

  return (
    <div className={styles.auth}>
      <Header fullWidth={true} />
      <div className={styles.mainImage}>
        <img
          src={LoginImage}
          alt="Login main image"
          width="540px"
          height="292px"
        />
      </div>
      {biometricAvailable ? (
        <>
          <div>{t("use-biometric-login")}</div>
          <button
            className={`${styles.margin} ${generalStyles.button}`}
            onClick={() => setBiometricAvailable(false)}
          >
            {t("negative-button-text")}
          </button>
        </>
      ) : (
        <form
          onSubmit={async (e) => {
            setSubmitting(true);
            e.preventDefault();
            await submitCredentials(email, password);
          }}
        >
          <div className={styles.box}>
            <div className={generalStyles.authTitle}>{t("login2")}</div>
            <div className={`${generalStyles.authText} ${styles.textMargin}`}>
              {t("warning")}
            </div>
            <input
              className={generalStyles.input}
              type="email"
              placeholder={t("email")}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required={true}
            />
            <PasswordInput
              placeholder={t("password")}
              value={password}
              setValue={setPassword}
              required={true}
            />
            <div className={styles.warningText}>
              <Link to="/auth/password-reset-request">{t("forgot")}</Link>
            </div>
            <button
              disabled={isSubmitting}
              type="submit"
              className={`${styles.margin} ${generalStyles.button}`}
            >
              {isSubmitting ? (
                <span className={generalStyles.spinner}></span>
              ) : (
                t("login")
              )}
            </button>

            {error ? (
              <div className={styles.errorText}>{t("error")}</div>
            ) : null}
            <div className={styles.separator}>{t("of")}</div>

            <div
              className={`${generalStyles.button} ${generalStyles.button_secondary}`}
            >
              <Link to="/auth/activate-account">{t("not-activated")}</Link>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
