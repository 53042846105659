import FormData from "form-data";
import Api from "./api/api";
import { Preferences } from "@capacitor/preferences";

class AuthService {
  public async login(email: string, password: string): Promise<boolean> {
    const formData = new FormData();

    formData.append("client_id", process.env.REACT_APP_CLIENT_ID);
    formData.append("grant_type", "password");
    formData.append("username", email);
    formData.append("password", password);

    try {
      const result = await Api.getApi().post<{
        token_type: string;
        access_token: string;
        refresh_token: string;
      }>("/oauth/token", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (!result.data.access_token) {
        return false;
      }

      localStorage.setItem("token", result.data.access_token);
      localStorage.setItem("type", result.data.token_type);
      localStorage.setItem("refresh", result.data.refresh_token);

      return true;
    } catch (_) {
      return false;
    }
  }

  public async requestPasswordReset(email: string): Promise<boolean> {
    try {
      const result = await Api.getApi().post(
        "/user/lost-password",
        { mail: email },
        { params: { _format: "json" } }
      );
      return !(result.status < 200 || result.status > 299);
    } catch (_) {
      localStorage.clear();
      return false;
    }
  }

  public async activateAccount(data: { email: string }): Promise<boolean> {
    try {
      const result = await Api.getApi().post("/jsonapi/activate-user-account", {
        email: data.email,
      });
      return !(result.status < 200 || result.status > 299);
    } catch (_) {
      return false;
    }
  }

  public async resetPassword(
    name: string,
    tempPassword: string,
    newPassword: string
  ): Promise<boolean> {
    try {
      const result = await Api.getApi().post(
        "/user/lost-password-reset",
        { name: name, temp_pass: tempPassword, new_pass: newPassword },
        { params: { _format: "json" } }
      );
      return !(result.status < 200 || result.status > 299);
    } catch (_) {
      localStorage.clear();
      return false;
    }
  }

  public async refreshToken(): Promise<boolean> {
    const refreshToken = localStorage.getItem("refresh");

    if (!refreshToken) {
      return false;
    }

    const formData = new FormData();

    formData.append("client_id", process.env.REACT_APP_CLIENT_ID);
    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", refreshToken);

    try {
      const result = await Api.getRefreshTokenApi().post<{
        token_type: string;
        access_token: string;
        refresh_token: string;
      }>("/oauth/token", formData);

      localStorage.setItem("token", result.data.access_token);
      localStorage.setItem("type", result.data.token_type);
      localStorage.setItem("refresh", result.data.refresh_token);

      return true;
    } catch (e) {
      return false;
    }
  }
}

export default new AuthService();
